import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Carousel from '../components/carousel';
import ReactMarkdown from 'react-markdown';

const ExamplePage = (props) => {
  const { data: { markdownRemark: { frontmatter }, site } } = props;
  function renderParagraph (props) {
    return <p className={' has-text-left-mobile'}>{props.children}</p>;
  }
  function renderLink (props) {
    return (<a href={props.href} target={'_blank'}>{props.children}</a>);
  }
  return (
    <Layout title={site.siteMetadata.title}>
      <SEO title={frontmatter.title} description={`Schittler Restaurierung Projektbeispiel - ${frontmatter.title}`}/>
      <section className="section">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            <h1 className="title">{frontmatter.title && frontmatter.title}</h1>
            <Link to={'/examples'} className='is-flex is-align-center'>
              <span style={{ fontSize: '1.2em' }} className={'mr-3'}>
                <i className="fas fa-chevron-left"/>
              </span>
              <p className="subtitle">{'zurück'}</p>
            </Link>
          </div>
        </div>
      </section>
      <section className="section pt-0">
        <div className="columns content">
          <div className={'column is-10 is-offset-1'}>
            <ReactMarkdown source={frontmatter.description} renderers={{ paragraph: renderParagraph, link: renderLink }}/>
          </div>
        </div>
      </section>
      <section className="section pt-0">
        <div className="columns content">
          <div className={'column is-10 is-offset-1 is-example'}>
            {frontmatter.images &&
              <Carousel
                images={frontmatter.images.filter(img => Boolean(img.image))}
                customHeight={1.2}
                options={{ autoPlay: false, showArrows: true }}
                fullImg={true}
                withSubtitle={true}
              />
            }
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
    query ExamplePage($id: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            frontmatter {
                title
                subtitle
                description
                images {
                    title
                    image {
                        id
                        childImageSharp {
                            fluid(maxWidth: 1024, quality: 75) {
                                ...GatsbyImageSharpFluid_withWebp,
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default ExamplePage;
